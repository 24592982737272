// recommendation function section
import { baseURL } from "../../Redux/baseURL"

// employee recommendation
export const getRecommendedJobsByJobTitle = (page) => {
    return fetch(baseURL + "/api/client/filterByResTitle", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            // "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({page: page}),
        credentials: 'include', // 'same-origin', 'omit' are other options
        // withCredentials: true, // This enables sending cookies cross-origin
    })
} 

export const getRecommendedJobsByCategory = (page) => {
    return fetch(baseURL + "/api/client/filterByResCategory", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            // "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify({page: page}),
        credentials: 'include', // 'same-origin', 'omit' are other options
        // withCredentials: true, // This enables sending cookies cross-origin
    });
} 

// employer recommendation

export const getRecommendedCandidate = (page) => {
  // default page is 1 till further notice
  let p = {
    page: !!page ? page : 1,
  };
  return fetch(baseURL + "/api/employer/recommendedClients", {
    method: "POST",
    body: JSON.stringify(p),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: 'include', // 'same-origin', 'omit' are other options
    // withCredentials: true, // This enables sending cookies cross-origin
  })
};

export const getRecommendedCandidateForAJob = (page, jobName) => {
  // default page is 1 till further notice
  let p = {
    page: !!page ? page : 1,
    jobName: jobName,
  };
  return fetch(baseURL + "/api/employer/recommendedClientsForAJob", {
    method: "POST",
    body: JSON.stringify(p),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: 'include', // 'same-origin', 'omit' are other options
    // withCredentials: true, // This enables sending cookies cross-origin
  })
};

export const getRecommendedCandidateByCategory = (page) => {
  // default page is 1 till further notice
  let p = {
    page: !!page ? page : 1,
  };
  return fetch(baseURL + "/api/employer/recommendedClientsCategory", {
    method: "POST",
    body: JSON.stringify(p),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: 'include', // 'same-origin', 'omit' are other options
    // withCredentials: true, // This enables sending cookies cross-origin
  });
};

export const getRecommendedCandidateBySkills = (page) => {
  // default page is 1 till further notice
  let p = {
    page: !!page ? page : 1,
  };
  return fetch(baseURL + "/api/employer/recommendedClientsForAJobBySkills", {
    method: "POST",
    body: JSON.stringify(p),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: 'include', // 'same-origin', 'omit' are other options
    // withCredentials: true, // This enables sending cookies cross-origin
  });
};

export const getRecommendedCandidatesByJobTags = (page) => {
  return fetch(baseURL + "/api/employer/filterByMyJobsTags", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          // "Authorization": "Bearer " + localStorage.getItem("token")
      },
      body: JSON.stringify({page: page}),
      credentials: "include"
  });
} 